// PAY.JP専用クレジットカード用のフォーム

<template lang="pug">
.payjp-card-form

  //- カードブランド一覧
  the-form-item(title='利用可能ブランド')
    ul.card-brands
      li.w-8.h-8.bg-no-repeat.bg-contain.inline-block.ml-2.first__ml-0(
        v-for='item in cardBrands',
        :class='item.code',
        :key='item.code'
      )

  //- カード番号
  the-form-item(
    title='カード番号!',
    :error='cardNumber.error',
    :is-failed='!!cardNumber.error',
    is-required
  )
    //- 入れ子にするのは、iPhone Safari12にて、
    //- focusした際にページ先頭に勝手にスクロールされることへの対策
    .p-2.input-text.w-64
      div(:id='cardNumber.id')

  //- カード有効期限
  the-form-item.mt-1(
    title='有効期限',
    :error='cardExpiry.error',
    :is-failed='!!cardExpiry.error',
    is-required
  )
    .p-2.input-text.w-32
      div(:id='cardExpiry.id')

  //- セキュリティコード
  the-form-item.mt-1(
    title='セキュリティコード',
    note='3桁の数字です(ただしアメックスのみ4桁です)。',
    :error='cardCvc.error',
    :is-failed='!!cardCvc.error',
    is-required
  )
    .p-2.input-text.w-32
      div(:id='cardCvc.id')
</template>

<script>
import TheFormItem from './the-form-item'

const cardBrands = [
  {
    name: 'Visa',
    code: 'visa'
  },
  {
    name: 'MasterCard',
    code: 'master'
  },
  {
    name: 'JCB',
    code: 'jcb'
  },
  {
    name: 'American Express',
    code: 'amex'
  },
  {
    name: 'Diners Club',
    code: 'diners'
  },
  {
    name: 'Discover',
    code: 'discover'
  }
]

// カード番号入力欄
// ID
const cardNumberId = 'payjp-card-form-card-number'
// スタイル
const cardNumberOptions = {
  placeholder: '0000 0000 0000 0000',
  style: {
    base: {
      '::placeholder': {
        color: '#aaa'
      }
    }
  }
}
// カード有効期限入力欄
// ID
const cardExpiryId = 'payjp-card-form-card-expiry'
// スタイル
const cardExpiryOptions = {
  style: {
    base: {
      '::placeholder': {
        color: '#aaa'
      }
    }
  }
}
// カードのセキュリティコード欄
// ID
const cardCvcId = 'payjp-card-form-card-cvc'
// スタイル
const cardCvcOptions = {
  placeholder: '000',
  style: {
    base: {
      '::placeholder': {
        color: '#aaa'
      }
    }
  }
}

export default {
  name: 'PayjpCardForm',

  components: {
    TheFormItem
  },

  data () {
    return {
      cardBrands,
      // カード番号
      cardNumber: {
        id: cardNumberId,
        elem: null,
        isOk: false,
        error: null
      },
      // カード有効期限
      cardExpiry: {
        id: cardExpiryId,
        elem: null,
        isOk: false,
        error: null
      },
      // カードのセキュリティコード
      cardCvc: {
        id: cardCvcId,
        elem: null,
        isOk: false,
        error: null
      }
    }
  },

  computed: {
    isOk () {
      return this.cardNumber.isOk && this.cardExpiry.isOk && this.cardCvc.isOk
    }
  },

  mounted () {
    const elements = payjp.elements()
    // カード番号入力欄の準備
    this.cardNumber.elem = elements.create('cardNumber', cardNumberOptions)
    this.cardNumber.elem.mount('#' + this.cardNumber.id)
    this.cardNumber.elem.on('change', this._onChageCardNumber)
    // 有効期限入力欄の準備
    this.cardExpiry.elem = elements.create('cardExpiry', cardExpiryOptions)
    this.cardExpiry.elem.mount('#' + this.cardExpiry.id)
    this.cardExpiry.elem.on('change', this._onChageCardExpiry)
    // セキュリティコードの準備
    this.cardCvc.elem = elements.create('cardCvc', cardCvcOptions)
    this.cardCvc.elem.mount('#' + this.cardCvc.id)
    this.cardCvc.elem.on('change', this._onChageCardCvc)
  },

  methods: {
    _onChageCardNumber (e) {
      this.cardNumber.isOk = e.complete
      this.cardNumber.error = e.error?.message
    },
    _onChageCardExpiry (e) {
      this.cardExpiry.isOk = e.complete
      this.cardExpiry.error = e.error?.message
    },
    _onChageCardCvc (e) {
      this.cardCvc.isOk = e.complete
      this.cardCvc.error = e.error?.message
    },
    // トークンの取得。外部からの利用のため
    async getToken () {
      if (!this.isOk) throw new Error('入力内容が正しくありません。')
      // トークン生成
      const result = await payjp.createToken(this.cardNumber.elem)
      if (result.error) throw new Error('トークンの生成に失敗しました。')
      return result.id
    }
  },

  watch: {
    isOk (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="sass" scoped>
.payjp-card-form
  ul.card-brands
    li
      &.visa
        background-image: url(../../../assets/visa.png)
      &.master
        background-image: url(../../../assets/master.png)
      &.jcb
        background-image: url(../../../assets/jcb.png)
      &.amex
        background-image: url(../../../assets/amex.png)
      &.diners
        background-image: url(../../../assets/diners.png)
      &.discover
        background-image: url(../../../assets/discover.png)
</style>
