import { render, staticRenderFns } from "./payjp-card-form.vue?vue&type=template&id=52dff423&scoped=true&lang=pug&"
import script from "./payjp-card-form.vue?vue&type=script&lang=js&"
export * from "./payjp-card-form.vue?vue&type=script&lang=js&"
import style0 from "./payjp-card-form.vue?vue&type=style&index=0&id=52dff423&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52dff423",
  null
  
)

export default component.exports