// 入力フォーム

<template lang="pug">
.order-form

  .step-1.mx-auto(v-if='step === 1')

    h2.text-3xl.font-bold.text-teal-700.lg__text-center ご注文手続き

    p.mt-4 必要事項をご入力の上、「内容の確認へ」ボタンを押してください。

    validation-observer(ref='observer', v-slot='{ invalid : invalidAtLeastOne }')
      //- 注文者
      section.mt-4
        h3.text-xl.text-green-700.border-b.border-green-400 ご注文者の情報

        validation-provider(v-slot='{ errors, failed }', rules='required')
          the-form-item.mt-5(
            title='お名前', :error='errors[0]', :is-failed='failed', is-required
          )
            input.input-text.block.w-full(
              type='text', v-model.trim='form.customer.name'
            )

        validation-provider(
          v-slot='{ errors, failed }',
          :rules='{ required: true, regex: /^[ぁ-ゖー\x20\u3000]*$/ }'
        )
          the-form-item.mt-1(
            title='ふりがな', :error='errors[0]', :is-failed='failed', is-required, note='ひらがなで入力してください。'
          )
            input.input-text.block.w-full(
              type='text', v-model.trim='form.customer.nameKana'
            )

        the-form-item.mt-1(title='メールアドレス')
          p {{ email }}

        validation-provider(
          v-slot='{ errors, failed }',
          :rules='{ required: true, regex: /^\\d{3}-?\\d{4}$/ }'
        )
          the-form-item.mt-1(
            title='郵便番号', :error='errors[0]', :is-failed='failed', is-required, note='ハイフンの有無は問いません。'
          )
            input.input-text.block.w-40(
              type='tel', v-model.trim='form.customer.zipcode'
            )

        validation-provider(v-slot='{ errors, failed }', rules='required')
          the-form-item.mt-1(
            title='都道府県', :error='errors[0]', :is-failed='failed', is-required
          )
            select.select(
              v-model='form.customer.prefecture'
            )
              option(value='') 選択してください...
              option(v-for='item in jpPrefectures') {{ item }}

        validation-provider(v-slot='{ errors, failed }', rules='required')
          the-form-item.mt-1(
            title='市区町村', :error='errors[0]', :is-failed='failed', is-required
          )
            input.input-text.block.w-full(
              type='text', v-model.trim='form.customer.city'
            )

        validation-provider(v-slot='{ errors, failed }', rules='required')
          the-form-item.mt-1(
            title='番地等', :error='errors[0]', :is-failed='failed', is-required
          )
            input.input-text.block.w-full(
              type='text', v-model.trim='form.customer.address2'
            )

        the-form-item.mt-1(
          title='建物名等'
        )
          input.input-text.block.w-full(
            type='text', v-model.trim='form.customer.address3'
          )

        validation-provider(
          v-slot='{ errors, failed }',
          :rules='{ required: true, regex: /^\\d{2,4}-?\\d{2,4}-?\\d{3,4}$/ }'
        )
          the-form-item.mt-1(
            title='TEL', :error='errors[0]', :is-failed='failed', is-required, note='ハイフンの有無は問いません。'
          )
            input.input-text.block.w-56(
              type='tel', v-model.trim='form.customer.tel'
            )

      //- お届け先
      section.mt-4
        h3.text-xl.text-green-700.border-b.border-green-400 お届け先の情報

        the-form-item.mt-5(title='お届け先', is-required)
          label
            input.checkbox(type='radio', value='same', v-model='destinationType')
            span ご注文者と同じ
          label.ml-4
            input.checkbox(type='radio', value='different', v-model='destinationType')
            span 別の住所へ送る

        the-form-item.mt-1(title='配送方法')
          p ヤマト運輸 ネコポス (送料 {{shippingCost}}円(税別))

        template(v-if='destinationType === "different"')

          validation-provider(v-slot='{ errors, failed }', :rules='{ required: destinationType === "different" }')
            the-form-item.mt-1(
              title='お名前', :error='errors[0]', :is-failed='failed', is-required
            )
              input.input-text.block.w-fullt(
                type='text', v-model.trim='form.destination.name'
              )

          validation-provider(
            v-slot='{ errors, failed }',
            :rules='{ required: destinationType === "different", regex: /^[ぁ-ゖー\x20\u3000]*$/ }'
          )
            the-form-item.mt-1(
              title='ふりがな', :error='errors[0]', :is-failed='failed', is-required, note='ひらがなで入力してください。'
            )
              input.input-text.block.w-full(
                type='text', v-model.trim='form.destination.nameKana'
              )

          validation-provider(
            v-slot='{ errors, failed }',
            :rules='{ required: destinationType === "different", regex: /^\\d{3}-?\\d{4}$/ }'
          )
            the-form-item.mt-1(
              title='郵便番号', :error='errors[0]', :is-failed='failed', is-required, note='ハイフンの有無は問いません。'
            )
              input.input-text.block.w-40(
                type='tel', v-model.trim='form.destination.zipcode'
              )

          validation-provider(v-slot='{ errors, failed }', :rules='{ required: destinationType === "different" }')
            the-form-item.mt-1(
              title='都道府県', :error='errors[0]', :is-failed='failed', is-required
            )
              select.select(
                v-model='form.destination.prefecture'
              )
                option(value='') 選択してください...
                option(v-for='item in jpPrefectures') {{ item }}

          validation-provider(v-slot='{ errors, failed }', :rules='{ required: destinationType === "different" }')
            the-form-item.mt-1(
              title='市区町村', :error='errors[0]', :is-failed='failed', is-required
            )
              input.input-text.block.w-full(
                type='text', v-model.trim='form.destination.city'
              )

          validation-provider(v-slot='{ errors, failed }', :rules='{ required: destinationType === "different" }')
            the-form-item.mt-1(
              title='番地等', :error='errors[0]', :is-failed='failed', is-required
            )
              input.input-text.block.w-full(
                type='text', v-model.trim='form.destination.address2'
              )

          the-form-item.mt-1(
            title='建物名等'
          )
            input.input-text.block.w-full(
              type='text', v-model.trim='form.destination.address3'
            )

          validation-provider(
            v-slot='{ errors, failed }',
            :rules='{ required: destinationType === "different", regex: /^\\d{2,4}-?\\d{2,4}-?\\d{3,4}$/ }'
          )
            the-form-item.mt-1(
              title='TEL', :error='errors[0]', :is-failed='failed', is-required, note='ハイフンの有無は問いません。'
            )
              input.input-text.block.w-56(
                type='tel', v-model.trim='form.destination.tel'
              )

      //- 支払い方法
      section.mt-4
        h3.text-xl.text-green-700.border-b.border-green-400 お支払い方法

        the-form-item.mt-5(title='お支払い方法', is-required)
          label.cursor-pointer
            input.checkbox(type='radio', value='card', v-model='form.payment.type')
            span クレジットカード(先払い)
          label.ml-4.cursor-pointer
            input.checkbox(type='radio', value='conveni', v-model='form.payment.type')
            span コンビニ払い(先払い)

        //- クレジットカード
        template(v-if='form.payment.type === "card"')

          //- allowFalse: falseとすることで、falseは許容しない
          validation-provider(
            :rules='{ required: form.payment.type === "card" ? { allowFalse: false } : false }'
          )
            //- すべての入力が完了するとisOkがtrueとなる
            payjp-card-form(
              v-model='form.payment.card.isOk',
              ref='cardForm'
            )

        //- コンビニ
        template(v-if='form.payment.type === "conveni"')

          the-form-item(title='')
            p.text-blue-700
              | コンビニ決済はイプシロン株式会社の決済代行システムを利用しております。ご注文後に
              b 「sendonly@epsilon.jp」
              | よりお支払方法に関するメールが送信されますので、スマートフォン等の迷惑メール設定のご確認をお願いいたします。

          validation-provider(
            v-slot='{ errors, failed }',
            :rules='{ required: form.payment.type === "conveni" }'
          )
            the-form-item.mt-1(
              title='コンビニの選択', :error='errors[0]', :is-failed='failed', is-required,
              note='お支払期限はご注文日より10日後となります。'
            )
              label.flex.items-center.mt-2.first__mt-0.cursor-pointer(v-for='item in conveniList')
                input(type='radio', :value='item.code', v-model='form.payment.conveni.type')
                .mx-1.conveni-icon.w-8.h-8.bg-no-repeat.bg-contain.inline-block(
                  :class='item.code'
                )
                span {{ item.name }}

      //- iOS12のSafariにおいて、iframeのinput要素を利用すると、
      //- スクリーンキーボードが、ページ遷移後も出たままになる問題への対応。
      //- ページ遷移前にこの要素にfocus&blurすることで、でなくなる。
      //- なお、display:noneやvisibility:hiddenだとダメな模様
      input.dummy.border-0.opacity-0(type='checkbox')

      .buttons.text-center.mt-10
        button.btn.bg-orange-500.text-white.px-10.text-xl(
          :disabled='invalidAtLeastOne ? "disabled" : null',
          @click.prevent='goToConfirm'
        )
          span 内容の確認へ
          fa-icon.ml-2(icon='arrow-right')
        br
        button.btn.mt-5(
          @click='goToCart'
        )
          fa-icon(icon='arrow-left')
          span.ml-2 カートへ

  //- 注文内容の確認
  .step-2.mx-auto(v-else-if='step === 2')
    h2.text-3xl.font-bold.text-teal-700.lg__text-center ご注文内容の確認

    template(v-if='cartDetail')
      table.border-collapse.w-full.bg-white.mt-4
        thead
          tr.bg-blue-200.border-t.border-b.border-blue-300
            th.p-1.font-normal.text-blue-700 商品
            th.p-1.font-normal.text-blue-700 単価(税別)
            th.p-1.font-normal.text-blue-700 数量
            th.p-1.font-normal.text-blue-700 小計(税別)
        tbody
          tr.border-t.border-b.border-blue-300(v-for='item in cartDetail.items')
            td.p-1
              | {{ item.bookName }}
              br
              | ({{ item.childName }} 様)
            td.p-1.text-right
              | {{ item.unitPrice }}円
            td.p-1.text-right
              | {{ item.quantity }}点
            td.p-1.text-right
              | {{ item.price }}円

      table.border-collapse.bg-white.mt-4.ml-auto
        tbody
          tr.border-t.border-b.border-blue-300
            th.px-2.py-1.font-normal.text-blue-700.bg-blue-200 商品金額
            td.w-32.px-2.py-1.text-right
              .float-left.text-blue-700 {{ cartDetail.totalQuantity }}点
              | {{ cartDetail.subTotal }}円
          tr.border-t.border-b.border-blue-300
            th.px-2.py-1.font-normal.text-blue-700.bg-blue-200 送料
            td.w-32.px-2.py-1.text-right {{ cartDetail.shippingCost }}円
          tr.border-t.border-b.border-blue-300
            th.px-2.py-1.font-normal.text-blue-700.bg-blue-200 消費税
            td.w-32.px-2.py-1.text-right
              .float-left.text-blue-700  {{ cartDetail.taxPercent }}%
              | {{ cartDetail.tax }}円
          tr.border-t.border-b.border-blue-300
            th.px-2.py-1.text-blue-700.bg-blue-200 合計(税込)
            td.font-bold.w-32.px-2.py-1.text-right.text-lg {{ cartDetail.total }}円

    .lg__flex.mt-10.items-stretch.bg-white.p-3.border.border-blue-300
      .customer.flex-1
        .px-3
          h3.text-center.font-bold.text-blue-700 ご注文者情報
          p.mt-2
            | {{ form.customer.name }} 様
            br
            | ({{ form.customer.nameKana }} 様)
            br
            | 〒{{ form.customer.zipcode }}
            br
            | {{ form.customer.prefecture }} {{ form.customer.city }}
            br
            | {{ form.customer.address2 }} {{ form.customer.address3 }}
            br
            | TEL: {{ form.customer.tel }}
      .destination.flex-1.lg__border-l-2.border-t-2.lg__border-t-0.mt-3.lg__mt-0.pt-3.lg__pt-0
        .px-3
          h3.text-center.font-bold.text-blue-700 お届け先
          p.mt-2(v-if='destinationType === "same"')
            | ご注文者と同じ
          //- 注文者と異なる
          p.mt-2(v-else)
            | {{ form.destination.name }} 様
            br
            | ({{ form.destination.nameKana }} 様)
            br
            | 〒{{ form.destination.zipcode }}
            br
            | {{ form.destination.prefecture }} {{ form.destination.city }}
            br
            | {{ form.destination.address2 }} {{ form.destination.address3 }}
            br
            | TEL: {{ form.destination.tel }}
          p.mt-2.text-green-800 ※ヤマト運輸ネコポスでのお届けとなります
      .payment.flex-1.lg__border-l-2.border-t-2.lg__border-t-0.mt-3.lg__mt-0.pt-3.lg__pt-0
        .px-3
          h3.text-center.font-bold.text-blue-700 お支払い方法
          p.mt-2(v-if='form.payment.type === "card"')
            | クレジットカード(先払い)
          p.mt-2(v-else)
            | コンビニ払い(先払い)
            br
            | お支払先: {{ userConveniName }}

    p.mt-2.text-gray-800
      | ・納期の目安は約3〜5週間です。
      br
      | ・注文確定を行ったブックの再編集はできませんのでご了承ください。

    label.mt-10.block.border.border-gray-400.bg-gray-100.p-3.text-center.cursor-pointer.text-lg.select-none(
      :class='{ "bg-yellow-200": agreement }'
    )
      input(type='checkbox', v-model='agreement')
      span.ml-2.underline 内容を確認しました。

    .buttons.mt-5.text-center
      button.btn.bg-red-500.text-white.px-10.text-xl(
        :disabled='agreement ? null : "disabled"',
        @click='doOrder'
      )
        fa-icon(icon='check')
        span.ml-2 ご注文確定
      br
      button.btn.mt-5(
        @click='goToForm'
      )
        fa-icon(icon='arrow-left')
        span.ml-2 内容を修正
</template>

<script>
import { mapActions } from 'vuex'
import get from 'lodash.get'

import TheFormItem from './components/the-form-item'
import PayjpCardForm from './components/payjp-card-form'
import jpPrefectures from '../../utils/jp-prefectures'
import conveniInfo from '../../utils/conveni-info'
import getProfileApi from '../../api/user-identities/get-profile'
import getCartApi from '../../api/cart/get'
import orderApi from '../../api/orders/order'
// import getCardToken from '../../utils/get-card-token'
import errorCodes from '../../errors/codes'

export default {
  name: 'OrderForm',

  components: {
    TheFormItem,
    PayjpCardForm
  },

  data () {
    return {
      // ステップ
      step: 1,
      // フォームの内容
      form: {
        // 注文者
        customer: {
          name: '',
          nameKana: '',
          zipcode: '',
          prefecture: '',
          city: '',
          address2: '',
          address3: '',
          tel: ''
        },
        // 送り先
        destination: {
          name: '',
          nameKana: '',
          zipcode: '',
          prefecture: '',
          city: '',
          address2: '',
          address3: '',
          tel: ''
        },
        payment: {
          type: 'card',
          card: {
            isOk: false,
            token: '',
            number: '',
            expMonth: '',
            expYear: '',
            cvv: ''
          },
          conveni: {
            type: ''
          }
        }
      },
      destinationType: 'same',
      // メールアドレス
      email: '',
      // 同意したか
      agreement: false,
      // カート内容
      cartDetail: null,
      // 2重送信防止のロック
      lock: false
    }
  },

  computed: {
    jpPrefectures () { return jpPrefectures },
    shippingCost () {
      return parseInt(process.env.VUE_APP_SHIPPING_COST, 10)
    },
    // ユーザが選択したコンビニ
    userConveniName () {
      if (!this.form.payment.conveni.type) return ''
      return conveniInfo[this.form.payment.conveni.type].name
    },
    // コンビニのリスト
    conveniList () {
      return [
        conveniInfo.seven,
        conveniInfo.famima,
        conveniInfo.lawson,
        conveniInfo.mini
      ]
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    ...mapActions({
      setError: 'app/setError'
    }),
    async init () {
      // まずカート情報の取得を試してみる
      await this.getCartInfo()
      // プロフィールの取得
      const response = await getProfileApi()
      if (!response.ok) {
        this.setError({ code: errorCodes.GET_PROFILE_ERROR })
        return
      }
      const profile = response.payload
      this.form.customer.name = profile.name
      this.form.customer.nameKana = profile.nameKana
      this.form.customer.zipcode = profile.zipcode
      this.form.customer.prefecture = profile.prefecture
      this.form.customer.city = profile.city
      this.form.customer.address2 = profile.address1
      this.form.customer.address3 = profile.address2
      this.form.customer.tel = profile.tel
      this.email = profile.email
    },
    // 確認表示へ
    async goToConfirm () {
      // ダミー要素へfocus&blurすることで、iOS Safariの
      // スクリーンキーボードが出たままになる問題に対応
      this.$el.querySelector('input.dummy').focus()
      this.$el.querySelector('input.dummy').blur()

      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        alert('入力に誤りがあります。')
        return
      }
      // カード決済の場合トークンを生成する
      if (this.form.payment.type === 'card') {
        try {
          await this._createCardToken()
        } catch (e) {
          alert('クレジットカード情報に誤りがあります。')
          return
        }
      }
      this.step = 2
    },
    // 最新のカートの状況を取得
    async getCartInfo () {
      try {
        const response = await getCartApi()
        if (!response.ok) {
          throw { code: errorCodes.GET_CART_ERROR }
        }
        // カートが空か
        if (response.payload.items.length === 0) {
          throw { code: errorCodes.ORDER_TARGET_IS_EMPTY }
        }
        this.cartDetail = response.payload
      } catch (e) {
        this.setError(e)
      }
    },
    // カートに戻る
    goToCart () {
      this.$router.push({ name: 'OrderCart' })
    },
    // フォームに戻る
    goToForm () {
      this.step = 1
    },
    // 注文確定
    async doOrder () {
      // すでに確定処理中か
      if (this.lock) return
      try {
        this.lock = true
        const body = {
          customer: this.form.customer,
          paymentType: this.form.payment.type
        }
        if (this.destinationType === 'different') body.destination = this.form.destination
        // カード決済の場合トークンを付加
        if (this.form.payment.type === 'card') {
          body.cardToken = this.form.payment.card.token
        } else {
          // コンビニ決済
          body.conveniType = this.form.payment.conveni.type
        }
        const response = await orderApi(body)
        if (!response.ok) {
          const code = get(response, 'payload.code')

          if (code === 'CARD_SECURITY_CODE_INVALID') { // 2021/2より移行のPAY.JPでは利用しないエラー
            throw { code: errorCodes.CARD_SECURITY_CODE_INVALID }
          } else if (code === 'CARD_EXP_INVALID') { // 2021/2より移行のPAY.JPでは利用しないエラー
            throw { code: errorCodes.CARD_EXP_INVALID }
          } else if (code === 'CARD_DATA_INVALID') { // 2021/2より移行のPAY.JP用のエラー
            throw { code: errorCodes.CARD_DATA_INVALID }
          } else if (code === 'CARD_EXPIRED') { // 2021/2より移行のPAY.JP用のエラー
            throw { code: errorCodes.CARD_EXPIRED }
          } else if (code === 'CARD_INVALID') {
            throw { code: errorCodes.CARD_INVALID }
          } else if (code === 'COMMIT_CONVENI_ERROR') {
            throw { code: errorCodes.COMMIT_CONVENI_ERROR }
          } else if (code === 'ORDER_TARGET_IS_EMPTY') {
            this.step = 3
            throw { code: errorCodes.ORDER_TARGET_IS_EMPTY }
          } else {
            this.step = 3
            throw { code: errorCodes.UNKNOWN_ORDER_ERROR }
          }
        }
        // バックボタン対策に引っかからないようstep=3に
        this.step = 3
        // 注文完了したら完了ページへ遷移
        this.$router.push({ name: 'OrderCompleted', params: { orderCode: response.payload.orderCode } })
      } catch (e) {
        this.setError(e)
      } finally {
        this.lock = false
      }
    },
    // クレジットカードのトークンの取得
    async _createCardToken () {
      if (!this.$refs.cardForm) {
        throw new Error('対象の要素が見つかりません。')
      }
      this.form.payment.card.token = await this.$refs.cardForm.getToken()
    }
  },

  watch: {
    // ステップが更新された、スクロール位置を戻す
    step (value) {
      window.scrollTo(0, 0)
      // ステップ2に遷移したらカートの情報取得
      if (value === 2) this.getCartInfo()
      // クレジットカードのフォームは初期化されるので、状態をfalseに戻す
      else if (value === 1) {
        this.form.payment.card.isOk = false
      }
    }
  },

  // ページ遷移
  // ブラウザバックを制御
  beforeRouteLeave (to, from, next) {
    // ステップ2の場合はブラウザバックされてもキャンセルしてステップ1へ
    if (this.step === 2) {
      this.step = 1
      next(false)
    } else {
      next()
    }
  }
}
</script>

<style lang="sass" scoped>
.order-form
  .step-1, .step-2
    max-width: 1100px
  .step-1
    ul.card-brands
      li
        &.visa
          background-image: url(../../assets/visa.png)
        &.master
          background-image: url(../../assets/master.png)
        &.jcb
          background-image: url(../../assets/jcb.png)
        &.amex
          background-image: url(../../assets/amex.png)
        &.diners
          background-image: url(../../assets/diners.png)
        &.deactive
          opacity: .3
    .conveni-icon
        &.seven
          background-image: url(../../assets/seven.gif)
        &.famima
          background-image: url(../../assets/famima.gif)
        &.lawson
          background-image: url(../../assets/lawson.gif)
        &.mini
          background-image: url(../../assets/mini.gif)

</style>
