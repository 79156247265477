// 注文確定

import api from '../api'

const path = '/orders'

export default ({
  customer,
  destination,
  paymentType,
  cardToken,
  conveniType
}) => {
  const body = {
    customer,
    payment: {
      type: paymentType
    }
  }
  if (destination) body.destination = destination
  if (paymentType === 'card') {
    body.payment.cardToken = cardToken
    body.payment.cardPaymentService = process.env.VUE_APP_CARD_PAYMENT_SERVICE
  } else body.payment.conveniType = conveniType

  return api({
    method: 'post',
    url: path,
    auth: true,
    params: body
  })
}
