// プロフィールの取得

import api from '../api'

const path = '/user-identities/profile'

export default () => {
  return api({
    url: path,
    auth: true
  })
}
