// フォームのアイテム

<template lang="pug">
.the-form-item(:class='{ "error": isFailed }')
  .lg__flex.items-center
    .left.lg__flex.items-center.justify-end
      span.tracking-wider.text-gray-700 {{ title }}
      span.ml-2.inline-block.bg-red-400.text-white.text-xs.font-bold.px-1.rounded(
        v-if='isRequired'
      ) 必須
    .right.flex-grow.lg__ml-4.mt-1.lg__mt-0
      slot
  .messages.mt-1.text-sm
    .text-blue-600(v-if='note') {{ note }}
    //- エラーメッセージ。ない場合もエラー表示時に上下に動かないように改行を表示
    .text-red-600(v-if='error') {{ error }}
    br.dummy(v-else)
</template>

<script>
export default {
  name: 'TheFormItem',

  props: {
    title: {
      type: String,
      required: true
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isFailed: {
      type: Boolean,
      default: false
    },
    note: {
      type: String
    },
    error: {
      type: String
    }
  }
}
</script>

<style lang="sass" scoped>
.the-form-item
  .left
    min-width: 12rem
  .messages
    +lg
      padding-left: 13rem
  &.error
    .left
      > :first-child
        color: #e53e3e
</style>
