// カートの情報を取得

import api from '../api'

const path = '/cart'

export default () => {
  return api({
    url: path,
    auth: true
  })
}
